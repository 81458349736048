import { history, navigateToPage } from 'core/routing/history';

const AD_SERVER_ROUTE = 'ad-server';
const ADVERTISERS_ROUTE = 'advertisers';
const CAMPAIGNS_ROUTE = 'campaigns';
const LINE_ITEMS_ROUTE = 'line-items';
const CREATIVES_ROUTE = 'creatives';
const AD_UNITS_ROUTE = 'ad-units';
const TARGETING_PRESETS_ROUTE = 'targeting-presets';
const MONITORING_ROUTE = 'monitoring';
const FORECASTING_ROUTE = 'forecasting';
const NEW = 'new';
export const MODE = 'mode';
export const EDIT = 'edit';
const DETAILS = 'details';

export const AD_SERVER_ROUTES = {
  AD_SERVER_BASE: `/${AD_SERVER_ROUTE}`,
  ADVERTISERS: `/${AD_SERVER_ROUTE}/${ADVERTISERS_ROUTE}`,
  CAMPAIGNS: `/${AD_SERVER_ROUTE}/${CAMPAIGNS_ROUTE}`,
  CREATE_CAMPAIGN: `/${AD_SERVER_ROUTE}/${CAMPAIGNS_ROUTE}/${NEW}`,
  EDIT_CAMPAIGN: `/${AD_SERVER_ROUTE}/${CAMPAIGNS_ROUTE}/:id`,
  LINE_ITEMS: `/${AD_SERVER_ROUTE}/${LINE_ITEMS_ROUTE}`,
  LINE_ITEM_DETAILS: `/${AD_SERVER_ROUTE}/${LINE_ITEMS_ROUTE}/${DETAILS}/:id`,
  EDIT_LINE_ITEM: `/${AD_SERVER_ROUTE}/${LINE_ITEMS_ROUTE}/:id`,
  LINE_ITEM_FROM_CAMPAIGN: `/${AD_SERVER_ROUTE}/${LINE_ITEMS_ROUTE}/:campaignId/:id`,
  CREATIVES: `/${AD_SERVER_ROUTE}/${CREATIVES_ROUTE}`,
  CREATE_CREATIVE: `/${AD_SERVER_ROUTE}/${CREATIVES_ROUTE}/${NEW}`,
  CREATE_CREATIVE_FROM_LINE_ITEM: `/${AD_SERVER_ROUTE}/${CREATIVES_ROUTE}/:lineItemId/${NEW}`,
  EDIT_CREATIVE: `/${AD_SERVER_ROUTE}/${CREATIVES_ROUTE}/:id`,
  EDIT_CREATIVE_FROM_LINE_ITEM: `/${AD_SERVER_ROUTE}/${CREATIVES_ROUTE}/:lineItemId/:id`,
  AD_UNITS: `/${AD_SERVER_ROUTE}/${AD_UNITS_ROUTE}`,
  TARGETING_PRESETS: `/${AD_SERVER_ROUTE}/${TARGETING_PRESETS_ROUTE}`,
  MONITORING: `/${AD_SERVER_ROUTE}/${MONITORING_ROUTE}`,
  FORECASTING: `/${AD_SERVER_ROUTE}/${FORECASTING_ROUTE}`,
};

export const adServerNavigator = {
  toAdvertisersPage() {
    navigateToPage(AD_SERVER_ROUTES.ADVERTISERS);
  },
  toCampaignsPage() {
    navigateToPage(AD_SERVER_ROUTES.CAMPAIGNS);
  },
  toCreateCampaignPage() {
    navigateToPage(AD_SERVER_ROUTES.CREATE_CAMPAIGN);
  },
  toEditCampaignPage(id: string, isEditMode = false) {
    const baseUrl = `${AD_SERVER_ROUTES.CAMPAIGNS}/${id}`;
    navigateToPage(isEditMode ? `${baseUrl}?${MODE}=${EDIT}` : baseUrl);
  },
  toLineItemsPage() {
    navigateToPage(AD_SERVER_ROUTES.LINE_ITEMS);
  },
  toCreateLineItemPage(campaignId: string) {
    navigateToPage(`${AD_SERVER_ROUTES.LINE_ITEMS}/${campaignId}/${NEW}`);
  },
  toEditLineItemPage(id: string) {
    navigateToPage(`${AD_SERVER_ROUTES.LINE_ITEMS}/${id}`);
  },
  toLineItemDetailsPage(id: string) {
    navigateToPage(`${AD_SERVER_ROUTES.LINE_ITEMS}/${DETAILS}/${id}`);
  },
  toCreativesPage() {
    navigateToPage(AD_SERVER_ROUTES.CREATIVES);
  },
  toCreateCreativePage() {
    navigateToPage(AD_SERVER_ROUTES.CREATE_CREATIVE);
  },
  toCreateCreativePageFromLineItem(lineItemId: string) {
    navigateToPage(`${AD_SERVER_ROUTES.CREATIVES}/${lineItemId}/${NEW}`);
  },
  toEditCreativePage(id: string) {
    navigateToPage(`${AD_SERVER_ROUTES.CREATIVES}/${id}`);
  },
  toEditCreativePageFromLineItem(id: string, lineItemId: string) {
    navigateToPage(`${AD_SERVER_ROUTES.CREATIVES}/${lineItemId}/${id}`);
  },
  toAdUnitsPage() {
    navigateToPage(AD_SERVER_ROUTES.AD_UNITS);
  },
  toForecastingPAge() {
    navigateToPage(AD_SERVER_ROUTES.FORECASTING);
  },
  goBack() {
    history.goBack();
  },
  goToPage(page: number) {
    history.go(page);
  },
};
