var p = function (s) {
    return s.Loaded = "loaded", s.ContextChanged = "contextChanged", s.UserData = "userData", s.Error = "error", s.MouseAndKeyboardEvent = "mouseAndKeyboardEvent", s.RouteChanged = "RouteChanged", s;
  }(p || {}),
  d = {},
  y = function y(e) {
    return e.replace(/\/$/, "");
  },
  u = function u(e) {
    return function (t) {
      if (t.origin === y(e)) {
        var n;
        try {
          n = JSON.parse(t.data);
        } catch (a) {
          if (a instanceof Error) {
            var g = a.message,
              s = a.name,
              c = a.stack;
            n = {
              type: "error",
              data: {
                message: g,
                name: s,
                stack: c
              }
            };
          }
        }
        var o = d[n.type];
        o !== void 0 && o(n);
      }
    };
  };
function M(e) {
  window.addEventListener("message", u(e), !1);
}
function v(e) {
  d.loaded = e;
}
function r(e, t, n) {
  t.contentWindow && t.contentWindow.postMessage(JSON.stringify(e), n);
}
function i(e, t) {
  window.parent.postMessage(JSON.stringify(e), t);
}
function f(e, t, n) {
  r({
    data: e,
    type: "userData"
  }, t, n);
}
function m(e, t) {
  window.addEventListener("keydown", function (n) {
    var a = {
      data: {
        type: n.type,
        key: n.key,
        code: n.code
      },
      type: "mouseAndKeyboardEvent"
    };
    r(a, e, t);
  }), window.addEventListener("click", function (n) {
    var a = {
      data: {
        type: n.type
      },
      type: "mouseAndKeyboardEvent"
    };
    r(a, e, t);
  });
}
function E(e) {
  i({
    data: null,
    type: "loaded"
  }, e);
}
function T(e, t) {
  i({
    data: t,
    type: "RouteChanged"
  }, e);
}
function l(e) {
  d.userData = e;
}
function x(e) {
  d.mouseAndKeyboardEvent = e;
}
function D(e) {
  d.RouteChanged = e;
}
export { p as MessageType, M as listenToMessages, v as onIframeLoaded, D as onRouteChange, l as onUserDataRecieved, x as onUserEventRecieved, m as sendEventsToIframe, E as sendIframeLoaded, T as sendRouteChanged, f as sendUserData };